import React, { type FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useHeight from '../../hooks/useHeight';
import useMaintenanceBanner from '../../hooks/useMaintenanceBanner';
import { buttonClick } from '../../utils/analytics';
import { pageMapper, storageKeys } from '../../utils/constants';
import { cn, joinValues } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import Divider from '../ui/Divider';
import HamburgerButtonTestA from '../ui/HamburgerButtonTestA';
import NavigationItemLine from '../ui/NavigationItemLine';
import RabobankLogo from '../../public/icons/rabobank_logo.svg';
import Banner from './Banner';
import NavigationContentTestA, {
  KennisItems,
  ProductItems,
  SupportItems,
} from './NavigationContentTestA';
import ProgressBar from './ProgressBar';

const navigationColor = {
  primary: {
    logo: 'fill-primary',
    text: 'text-primary',
    hamburger: 'primary',
    border: 'border-primary',
  },
  secondary: {
    logo: 'fill-secondary',
    text: 'text-secondary',
    hamburger: 'secondary',
    border: 'border-secondary',
  },
  white: {
    logo: 'fill-white',
    text: 'text-white',
    hamburger: 'white',
    border: 'border-white',
  },
  black: {
    logo: 'fill-off-black',
    text: 'text-off-black',
    hamburger: 'black',
    border: 'border-off-black',
  },
} as const;

export const navigationBackground = {
  transparent: {
    base: 'bg-transparent',
    scroll: 'bg-white',
  },
  white: {
    base: 'bg-white',
    scroll: 'bg-white',
  },
  gray: {
    base: 'bg-gray-background',
    scroll: 'bg-gray-background',
  },
};

export const navigationTheme = {
  PRIMARY_PRIMARY: {
    base: navigationColor.primary,
    scroll: navigationColor.primary,
  },
  PRIMARY_SECONDARY: {
    base: navigationColor.primary,
    scroll: navigationColor.secondary,
  },
  PRIMARY_WHITE: {
    base: navigationColor.primary,
    scroll: navigationColor.white,
  },
  PRIMARY_BLACK: {
    base: navigationColor.primary,
    scroll: navigationColor.black,
  },
  SECONDARY_SECONDARY: {
    base: navigationColor.secondary,
    scroll: navigationColor.secondary,
  },
  SECONDARY_PRIMARY: {
    base: navigationColor.secondary,
    scroll: navigationColor.primary,
  },
  SECONDARY_WHITE: {
    base: navigationColor.secondary,
    scroll: navigationColor.white,
  },
  SECONDARY_BLACK: {
    base: navigationColor.secondary,
    scroll: navigationColor.black,
  },
  WHITE_WHITE: {
    base: navigationColor.white,
    scroll: navigationColor.white,
  },
  WHITE_PRIMARY: {
    base: navigationColor.white,
    scroll: navigationColor.primary,
  },
  WHITE_SECONDARY: {
    base: navigationColor.white,
    scroll: navigationColor.secondary,
  },
  WHITE_BLACK: {
    base: navigationColor.white,
    scroll: navigationColor.black,
  },
  BLACK_BLACK: {
    base: navigationColor.black,
    scroll: navigationColor.black,
  },
  BLACK_PRIMARY: {
    base: navigationColor.black,
    scroll: navigationColor.primary,
  },
  BLACK_SECONDARY: {
    base: navigationColor.black,
    scroll: navigationColor.secondary,
  },
  BLACK_WHITE: {
    base: navigationColor.black,
    scroll: navigationColor.white,
  },
};

type BackgroundType = typeof navigationBackground[keyof typeof navigationBackground];

type ThemeType = typeof navigationTheme[keyof typeof navigationTheme];

type NavigationBarTestAProps = {
  className: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  theme: ThemeType;
  maxWidth: string;
  background: BackgroundType;
  showProgressBar: boolean;
  distance: number;
  parentRef: React.MutableRefObject<HTMLElement>;
  activeId: number;
  setActiveId: (value?: number) => void;
};

function NavigationBarTestA({
  className,
  isOpen,
  setIsOpen,
  theme = navigationTheme.PRIMARY_SECONDARY,
  maxWidth = '',
  background = navigationBackground.transparent,
  showProgressBar,
  distance,
  parentRef,
  activeId,
  setActiveId,
}: NavigationBarTestAProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [ref, navigationHeight] = useHeight();
  const component = 'navigation_bar';

  const scrollColor = distance === 1 ? theme.scroll : theme.base;
  const isOpenColor = navigationColor.secondary;

  const location = useLocation();
  const { pathname } = location;

  const { isReady, showBanner, message } = useMaintenanceBanner();
  const isReconsent = pathname.includes(pageMapper.reconsent);
  const showMaintenenceBanner = isReady && showBanner && !isReconsent;

  const navHeaderRef = useRef<HTMLUListElement>(null);
  const navParentRef = useRef<HTMLDivElement>(null);

  const activeHeaderRef = navHeaderRef?.current?.children[activeId - 1];
  const headerLineWidth = (activeHeaderRef as HTMLElement)?.offsetWidth;

  const navHeaderLeft = activeHeaderRef?.getBoundingClientRect().left;
  const navParentLeft = navParentRef?.current?.getBoundingClientRect().left;

  const headerLinePosition = navHeaderLeft && navParentLeft
    ? navHeaderLeft - navParentLeft
    : 0;

  const itemsMapper: Record<number, FC<{ callback?: () => void }>> = {
    1: ProductItems,
    2: KennisItems,
    3: SupportItems,
  };

  const Item = itemsMapper[activeId];

  const navigateHome = () => {
    const event = t('event.navigation.back_to_home', { component });
    buttonClick(event);

    parentRef?.current?.scroll({ top: 0, behavior: 'smooth' });
    navigate(pageMapper.home);
  };

  const onMouseLeave = () => {
    setActiveId();
    setIsOpen(false);
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const { offsetHeight } = ref.current;
    sessionStorage.setItem(storageKeys.navigationHeight, offsetHeight.toString());
  }, [navigationHeight]);

  return (
    <>
      {showMaintenenceBanner && <Banner label={message} />}

      <div ref={ref} className={cn('sticky top-0 z-20 flex flex-col w-full', className)}>
        <div
          className={cn('flex justify-center w-full lg:absolute lg:bg-white lg:border-b lg:border-gray-medium', isOpen && 'drop-shadow-md')}
          onMouseLeave={onMouseLeave}
        >
          <ComponentLayout className={cn('flex-col w-full gap-6 xl:px-20 py-6', maxWidth)}>
            <div className="flex items-center justify-between w-full" ref={navParentRef}>
              <button
                onClick={navigateHome}
                type="button"
                name="navi_logo_button"
                id="navi_logo_button"
                aria-label="navi_logo_button"
                className="z-10"
              >
                <RabobankLogo
                  className={joinValues({
                    base: 'h-6 hover:fill-primary',
                    desktop: 'lg:opacity-100',
                    animation: 'navigation-header',
                    color: isOpen ? 'fill-secondary' : cn(scrollColor.logo, 'lg:fill-secondary'),
                    xl: 'xl:fill-secondary',
                  })}
                />
              </button>

              <HamburgerButtonTestA
                className="z-10 lg:hidden"
                color={isOpen ? isOpenColor.hamburger : scrollColor.hamburger}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />

              <NavigationContentTestA
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                setActiveId={setActiveId}
                ref={navHeaderRef}
              />
            </div>

            {isOpen && Item && (
            <>
              <div className="relative hidden lg:flex justify-end items-center">
                <Divider className="w-full" />
                <div
                  style={{ width: headerLineWidth, left: headerLinePosition }}
                  className="absolute top-1/2 w-full"
                >
                  <NavigationItemLine />
                </div>
              </div>

              <div className="hidden w-full items-center justify-center lg:flex">
                <Item />
              </div>
            </>
            )}

          </ComponentLayout>
        </div>

        {showProgressBar && <ProgressBar className="lg:hidden" distance={distance} />}

        {/* Mobile background */}
        <div
          style={{ opacity: distance }}
          className={joinValues({
            base: 'w-full bg-white -z-20',
            ProgressBar: showProgressBar ? 'bottom-[18px]' : 'bottom-0',
            position: 'absolute -top-8 left-0',
            shadow: !showProgressBar && 'drop-shadow-rabobank',
            lg: 'lg:hidden',
          })}
        />

        {/* Desktop background */}
        <div
          className={joinValues({
            base: 'hidden w-full -z-20',
            opacity: distance === 1 ? 'opacity-100' : 'opacity-0',
            position: 'absolute -top-8 bottom-0 left-0',
            shadow: 'drop-shadow-rabobank',
            animation: 'transition-opacity duration-300',
            background: background.scroll,
            lg: 'lg:block',
          })}
        />
      </div>
    </>
  );
}

export default NavigationBarTestA;
