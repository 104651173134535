import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import useNewStartFlow from '../../hooks/useNewStartFlow';
import { measureLinkClick } from '../../utils/analytics';
import { pageMapper } from '../../utils/constants';
import { cn, getQueryParams, joinValues } from '../../utils/helpers';
import Button from '../ui/Button';
import Link from '../ui/Link';
import Text from '../ui/Text';

type NavigationContentProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  distance: number;
};

function NavigationContent({ isOpen, setIsOpen, distance = 0 }: NavigationContentProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isReady, treatment } = useNewStartFlow();
  const newStartFlow = isReady && treatment === 'on';

  const [isEligibilityButtonHidden, setIsEligibilityButtonHidden] = useState(false);

  const home = {
    page: pageMapper.home,
    langKey: 'home',
  };

  const pages = [
    {
      page: pageMapper.zakelijkeLening,
      langKey: 'kortlopende_lening',
    },
    {
      page: pageMapper.zakelijkKrediet,
      langKey: 'kortlopend_krediet',
    },
    {
      page: pageMapper.faq,
      langKey: 'faq',
    },
    {
      page: pageMapper.contact,
      langKey: 'contact',
    },
  ];

  const queryParams = getQueryParams();

  const utmTags = Object.keys(queryParams)
    .map((id) => `${id}=${queryParams[id]}`)
    .join('&');

  const getPageString = () => {
    if (location.pathname === pageMapper.zakelijkeLening) {
      return ' - PBL';
    }

    if (location.pathname === pageMapper.zakelijkKrediet) {
      return ' - PBC';
    }

    return '';
  };

  const startYourApplicationHandler = () => {
    measureLinkClick(`Start your application - top${getPageString()}`);
    navigate(pageMapper.aboutYou, { state: { utmTags } });
  };

  const hideEligibilityButton = location.pathname !== pageMapper.home
    && location.pathname !== pageMapper.zakelijkeLening
    && location.pathname !== pageMapper.zakelijkKrediet;

  useEffect(() => {
    setIsEligibilityButtonHidden(hideEligibilityButton);
  }, [location.pathname]);

  return (
    <>
      {/* Mobile */}
      <nav
        className={joinValues({
          base: 'fixed flex flex-col gap-6 top-0 left-0 w-full p-10 h-screen pt-24 bg-white',
          scroll: 'overflow-auto',
          desktop: 'lg:hidden',
          transition: 'transform delay-100 transition-all duration-300',
          open: isOpen ? 'translate-x-0 sm:translate-x-2/4' : 'delay-300 translate-x-full',
        })}
      >
        <ul className="flex flex-col items-start w-full gap-6">
          {[home, ...pages].map(({ page, langKey }) => {
            const isActivePage = location.pathname === page;
            const color = isActivePage ? 'primary' : 'secondary';

            return (
              <li key={page}>
                <Link
                  onClick={() => setIsOpen(false)}
                  href={page}
                  disabled={isActivePage}
                >
                  <Text
                    langKey={`navigation.${langKey}`}
                    element="nav"
                    size="lg"
                    weight="semibold"
                    color={color}
                    className="hover:text-[inherit]"
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>

      {/* Desktop */}
      <nav
        className={joinValues({
          base: 'hidden border-inherit',
          spacing: newStartFlow ? '-mr-24' : '-mr-8',
          desktop: 'lg:flex',
        })}
      >
        <ul className={joinValues({
          base: 'flex w-full gap-6 transition-colors duration-500 border-inherit',
          align: newStartFlow && 'items-center',
        })}
        >
          {pages.map(({ page, langKey }) => {
            const isActivePage = location.pathname === page;

            return (
              <li key={page} className="flex flex-col border-inherit">
                <Link href={page} disabled={isActivePage}>
                  <Text
                    weight="semibold"
                    size="sm"
                    langKey={`navigation.${langKey}`}
                    color="custom"
                    className={cn('text-inherit')}
                  />
                </Link>
                {location.pathname === page && (
                  <motion.div
                    className="hidden -mt-0.5 border border-solid border-inherit lg:block"
                    layoutId="underline"
                    transition={{ duration: distance === 0 ? 0.2 : 0 }}
                  />
                )}
              </li>
            );
          })}
          {!isEligibilityButtonHidden && newStartFlow && distance === 1 && (
            <Button
              className="justify-end p-0 mr-10 font-semibold"
              label={t('eligibility.button')}
              name="go_to_rekening_courant"
              color="primary"
              variant="base"
              onClick={startYourApplicationHandler}
            />
          )}
        </ul>
      </nav>
    </>
  );
}

export default NavigationContent;
